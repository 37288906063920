import React from "react"
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-deferred';


class SingleBarChart extends React.Component {
  constructor(props) {
    super(props);
    this.chartReference = React.createRef();
  }
 
  componentDidMount() {
    //console.log(this.chartReference); // returns a Chart.js instance reference
  }
 
  render() {
    const data = this.props.data;
    const x_label = data.barChart.xLabel;
    const dataArray = data.barChart.dataValues;
    const x_label_tool = data.barChart.tooltipXLabelOptions;
    const x_unit = data.barChart.xUnitLabel;
    const x_unit_tool = data.barChart.tooltipXUnitOptions;
    const y_label = data.barChart.yLabel;
    const y_label_tool = data.barChart.tooltipYLabelOptions;
    const y_unit = data.barChart.yUnitLabel;
    const y_unit_tool = data.barChart.tooltipYUnitOptions;
    
    // console.log(data)
  
    let data_x = [];
    let data_y = [];
    dataArray.map((bit) => {
      data_x.push(bit.xDataValue);
      data_y.push(bit.dataValue);
      return bit;
    })

    // const theData = {
    //   labels: data_x,
    //   datasets: [{
    //       label: data_x,
    //       data: data_y,
    //   }]
    // }
    
    const getData = () => {
      // const ctx = canvas.getContext("2d")
      // const gradient = ctx.createLinearGradient(0, 0, 0, 400);
      // gradient.addColorStop(0, 'rgba(255,172,25,1)');   
      // gradient.addColorStop(1, 'rgba(241,69,54,1)');
      return{
        labels: data_x,
        datasets: [{
          label: data_x,
          data: data_y,
          backgroundColor:' #fd9c16',
          borderColor:'#EE6B4B',
          hoverBackgroundColor:'#EE6B4B',
          // backgroundColor : gradient
        }]
      }
    }

  
    const chartData = getData();

    const scales = {
      yAxes: [{
        scaleLabel:{
          display:false,
          labelString: y_label
        },
        ticks: {
          beginAtZero: true,
          callback: function(value, index, values) {
              return value;
          }
        }
      }],
      xAxes: [{
        scaleLabel:{
          display:false,
          labelString: x_label
        },
        ticks: {
          beginAtZero: true,
          callback: function(value, index, values) {
            return value;
          }
        }
      }] 
    }

    const options = {
      legend: {
        display: false
      },
      plugins: {
        deferred: {
          xOffset: 150,   // defer until 150px of the canvas width are inside the viewport
          yOffset: '50%', // defer until 50% of the canvas height are inside the viewport
          delay: 500      // delay of 500 ms after the canvas is considered inside the viewport
        }
      },
      scales,
      responsive:true,
      maintainAspectRatio: false, 
      tooltips: {
        custom: function(tooltip) {
          if (!tooltip) return;
          // disable displaying the color box;
          tooltip.displayColors = false;
        },
        callbacks: {
          // use label callback to return the desired label
          label: function(tooltipItem, data) {

            let x_prelabel,
                x_postlabel,
                y_prelabel,
                y_postlabel,
                x_label_combined,
                y_label_combined;

            if(x_label_tool === 'Before Data'){
              x_prelabel = x_label;
              x_label_combined = x_prelabel+tooltipItem.xLabel;
            }

            if(x_unit_tool === 'Before Data'){
              x_prelabel = x_unit;
              x_label_combined = x_prelabel+tooltipItem.xLabel;
            }

            if(x_label_tool === 'After Data'){
              x_postlabel = x_label;
              x_label_combined = tooltipItem.xLabel+x_postlabel;
            }

            if(x_unit_tool === 'After Data'){
              x_postlabel = x_unit;
              x_label_combined = tooltipItem.xLabel+x_postlabel;
            }

            if(y_label_tool === 'Before Data'){
              y_prelabel = y_label;
              y_label_combined = y_prelabel+tooltipItem.yLabel;
            }

            if(y_unit_tool === 'Before Data'){
              y_prelabel = y_unit;
              if(y_unit === '£'){
                y_label_combined = y_prelabel+tooltipItem.yLabel.toLocaleString();
              }else{
                y_label_combined = y_prelabel+" "+tooltipItem.yLabel;
              }
            }

            if(y_label_tool === 'After Data'){
              y_postlabel = y_label;
              y_label_combined = tooltipItem.yLabel+y_postlabel;
            }

            if(y_unit_tool === 'After Data'){
              y_postlabel = y_unit;
              y_label_combined = tooltipItem.yLabel+y_postlabel;
            }

            let label;
            if(x_label_combined !== undefined){
              label = x_label_combined + " | " + y_label_combined;
            }else{
              label = tooltipItem.xLabel + " | " + y_label_combined;
            }
            
            return label;
          
          },
          // remove title
          title: function(tooltipItem, data) {
            return;
          }
        }
      }
    };

    return (

      <>
      {/* Single Bar chart */}
      <Bar ref={this.chartReference} data={chartData} options={options} scales={scales} className="barChart"/>
    </>
    )
  }
}



export default SingleBarChart