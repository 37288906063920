import React, {useState} from "react"
import VisibilitySensor from 'react-visibility-sensor';
import Image from '../ResuableImageWrapper';
import "../../../sass/components/reuseable_components/_image-triples.scss";


const Service3Images = (data) => {
  
  let classList = [
    'images-triple',
    'section',
  ]; 
  
  const images = data.imagesX3;
  const removeBottomMargin = data.removeBottomMargin;

  if(removeBottomMargin === true){
    classList.push('no-margin');
  }

  const [isInview, setIsInview] = useState(false);
  
  function inviewOnChange (isVisible) {
    setIsInview(isVisible)
  }
  
  return (
    <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInview}  onChange={inviewOnChange}>
      {({isVisible}) =>
        <section className={`${classList.join(" ")} ${isInview ? 'is_inview' : ''}`} data-bg-shape="show_shape">
          <div className="wrapper-large">
            {images.map((image, index) => {
              return(
                <div className={index === 0 ? "image-wrapper image-left" : index === 1 ? "image-wrapper image-center" : index === 2 ? 'image-wrapper image-right' : "" } >
                  <Image  
                    image={image?.image?.localFile?.childImageSharp?.fluid} 
                    alt={image?.image?.altText}
                  />
                </div>
              )
              
            })}
          </div>
        </section>
      }
    </VisibilitySensor>
  )
}

export default Service3Images
