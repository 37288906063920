import React, {useState, useEffect} from "react"
import parse from 'html-react-parser';
import styled from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor';
import "../../../sass/components/reuseable_components/_text-areas.scss";

  const ContainsCustomColours = styled.div`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6{
      background:var(--grey--dark);
      background: -webkit-linear-gradient(right, ${(props) => props.customColour1}, ${(props) => props.customColour2});
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    
  `



const BasicText2ColBlock = ({ addDividerLine, removeBottomMargin, text, text2 }) => {
  let classList = [
    'text-area-2col', 
    'contains-custom-colour',
    'section'
  ]; 

  if(removeBottomMargin === true){
    classList.push('no-margin');
  }

      

  const [customColour1, setCustomColour1] = useState();
  const [customColour2, setCustomColour2] = useState();
  const [isInview, setIsInview] = useState(false);

  function inviewOnChange (isVisible) {
    setIsInview(isVisible)
  }
  
  useEffect(() => {
    if(typeof document !== 'undefined'){
        setCustomColour1(document.querySelector('.outer-component-wrapper').getAttribute('data-color1'))
        setCustomColour2(document.querySelector('.outer-component-wrapper').getAttribute('data-color2'))
    }
  }, [])

  
  return (
    <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInview}  onChange={inviewOnChange}>
      {({isVisible}) =>
        <section className={`${classList.join(" ")} ${isInview ? 'is_inview' : ''}`}  data-bg-shape="show_shape">
          <div className="wrapper-900">
              { addDividerLine ? <div className="line"></div> : "" }
              <ContainsCustomColours customColour1={customColour1} customColour2={customColour2} className="col-1 col">{parse(text)}</ContainsCustomColours>
              <ContainsCustomColours customColour1={customColour1} customColour2={customColour2} className="col-2 col">{parse(text2)}</ContainsCustomColours>
          </div>
        </section>
      }
    </VisibilitySensor>
  )
}

export default BasicText2ColBlock
