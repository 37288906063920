import React, {useState, useEffect} from "react"
import parse from 'html-react-parser';
import styled from 'styled-components';
import {Link} from 'gatsby';
import SingleBarChart from "../../charts/SingleBarChart";
import MultiBarChart from "../../charts/MultiBarChart";
import VisibilitySensor from 'react-visibility-sensor';
import "../../../sass/components/reuseable_components/_chart_text.scss";

const ContainsCustomColours = styled.div`

  h1,
  h2,
  h3,
  h4,
  h5,
  h6{
    background:var(--black);
    background: -webkit-linear-gradient(right, ${(props) => props.customColour1}, ${(props) => props.customColour2});
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  ul{
    li:before{
      background-color: ${(props) => props.customColour2} !important; 
    }

    &.styled_bullet_list{
      color: ${(props) => props.customColour2} !important; 
    }
  }

  .chart_wrapper{
  position: relative; 
  min-height: 50vh; 
  max-height: 500px;

    .x-label{
    margin-bottom:2rem;
    left:0;
    width: 100%;
    margin-top:1rem;
    }

    .y-label{
    left: -2rem;
    writing-mode: vertical-lr;
    height: 100%;
    transform: rotate(180deg);
    position: absolute;
    }

    .x-label,
    .y-label{
    text-align: center;
    font-size: 1.3rem;
    color: grey;;
    }

    .x-label{
    position: relative;
    }
    .y-label{
      position: absolute;
    }

    canvas{
    position: relative;
    }

  }
`

const ChartWrapper = styled.div`

`

export default function ServiceLineChartTextButton(data) {
  const chartData = data;

  let classList = [
    'chart-text', 
    'contains-custom-colour',
    'section'
  ]; 

  const singleOrMultipleDatasets = chartData.singleOrMultipleDatasets;
  const text = chartData.text;
  const button = chartData.button;

    
  
  const [isInview, setIsInview] = useState(false);
  const [customColour1, setCustomColour1] = useState();
  const [customColour2, setCustomColour2] = useState();
  
  useEffect(() => {
    if(typeof document !== 'undefined'){
        setCustomColour1(document.querySelector('.outer-component-wrapper').getAttribute('data-color1'))
        setCustomColour2(document.querySelector('.outer-component-wrapper').getAttribute('data-color2'))
    }
  }, [])

  function inviewOnChange (isVisible) {
    setIsInview(isVisible)
  }
  
  const x_label = chartData.barChart.xLabel || chartData.barChart2datasets.xLabel;
  const y_label = chartData.barChart.yLabel || chartData.barChart2datasets.yLabel;

  return (
    <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInview}  onChange={inviewOnChange}>
    {({isVisible}) =>
      <section className={`${classList.join(" ")} ${isInview ? 'is_inview' : ''}`} data-bg-shape="show_shape">
        <ContainsCustomColours customColour1={customColour1} customColour2={customColour2} className="wrapper">
          
          <ChartWrapper className={text ? 'chart_wrapper' : 'chart_wrapper full-width'}>
            <div className="y-label label">{y_label}</div>
            {
              singleOrMultipleDatasets === 'single' ? <SingleBarChart data={chartData} /> : <MultiBarChart data={chartData} />
            }
            <div className="x-label label">{x_label}</div>
          </ChartWrapper>
          
          {text ? 
            <div className="text-area no-margin">
              { parse(text) }
              {button.buttonText ? <Link to={button.buttonLink.link} className="btn btn-green-grad">{button.buttonText}</Link> : "" }
            </div>
          : ''}
        </ContainsCustomColours>
      </section>
    }
  </VisibilitySensor>
  )
}
